<template>
  <div class="admin-stats-annual">
    <div class="header">
      <div class="search">
        <div class="row">
          <div class="col source">
            <select class="form-control" v-model="state.args.year" @change="changeYear()" :disabled="!state.loaded">
              <option :value="0">전체</option>
              <option :value="y" v-for="(y, idx) in years" :key="idx">{{ y }}년</option>
            </select>
            <span class="badge balloon bg-default color-white" v-if="!state.args.utmSource">연도 선택</span>
          </div>
        </div>
      </div>
    </div>
    <div class="article" :class="{ skeleton: !state.loaded}">
      <template>
        <div class="alert alert-secondary">
          <i class="fa fa-info-circle"></i>
          <span>&nbsp; 검색 연도는 프로젝트 오픈일 기준입니다.</span>
        </div>
        <div class="row">
          <div class="item col-lg-6">
            <div class="subject">
              <span class="text">후원형 개수</span>
              <span class="desc">(성공 KIA + 성공 AON / KIA + 성공 AON / 전체)</span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.counts.success.sum) }}</b>
              <span>개</span>
              <i>/</i>
              <b>{{ $lib.getNumberFormat(state.counts.success.reward) }}</b>
              <span>개</span>
              <i>/</i>
              <b>{{ $lib.getNumberFormat(state.counts.total.reward) }}</b>
              <span>개</span>
            </div>
          </div>
          <div class="item col-lg-6">
            <div class="subject">
              <span class="text">증권형 개수</span>
              <span class="desc">(성공 / 전체)</span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.counts.success.invest) }}</b>
              <span>개</span>
              <i>/</i>
              <b>{{ $lib.getNumberFormat(state.counts.total.invest) }}</b>
              <span>개</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item col-lg-6">
            <div class="subject">
              <span class="text">후원형 성공률</span>
              <span class="desc">(성공 KIA + 성공 AON / KIA + 성공 AON)</span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.counts.success.sum / state.counts.total.reward * 100) }}</b>
              <span>%</span>
              <i>/</i>
              <b>{{ $lib.getNumberFormat(state.counts.success.reward / state.counts.total.reward * 100) }}</b>
              <span>%</span>
            </div>
          </div>
          <div class="item col-lg-6" v-if="state.counts.total.invest">
            <div class="subject">
              <span class="text">증권형 성공률</span>
              <span class="desc"></span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.counts.success.invest / state.counts.total.invest * 100) }}</b>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item col-lg-6">
            <div class="subject">
              <span class="text">후원형 목표 금액</span>
              <span class="desc">(합계)</span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.amounts.target.reward) }}</b>
              <span>원</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item col-lg-6">
            <div class="subject">
              <span class="text">후원형 후원자 수</span>
              <span class="desc">(전체)</span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.amounts.investor.reward) }}</b>
              <span>명</span>
            </div>
          </div>
          <div class="item col-lg-6">
            <div class="subject">
              <span class="text">증권형 투자자 수</span>
              <span class="desc">(전체)</span>
            </div>
            <div class="value">
              <b>{{ $lib.getNumberFormat(state.amounts.investor.invest) }}</b>
              <span>명</span>
            </div>
          </div>
        </div>
      </template>
      <div class="graphs" v-if="state.args.year === 0 && state.graph.loaded">
        <div class="row">
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">후원형 개수</span>
              <div class="float-right">(단위: 개)</div>
            </div>
            <LineGraph :data="state.graph.count.reward"/>
          </div>
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">증권형 개수</span>
              <div class="float-right">(단위: 개)</div>
            </div>
            <LineGraph :data="state.graph.count.invest"/>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">후원형 모집 금액</span>
              <div class="float-right">(단위: 원)</div>
            </div>
            <LineGraph :data="state.graph.amount.reward"/>
          </div>
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">증권형 청약 금액</span>
              <div class="float-right">(단위: 원)</div>
            </div>
            <LineGraph :data="state.graph.amount.invest"/>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">후원형 목표 금액</span>
              <div class="float-right">(단위: 원)</div>
            </div>
            <LineGraph :data="state.graph.target.reward"/>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">후원형 후원자 수</span>
              <div class="float-right">(단위: 명)</div>
            </div>
            <LineGraph :data="state.graph.investor.reward"/>
          </div>
          <div class="col-lg-6">
            <div class="subject">
              <span class="text">증권형 투자자 수</span>
              <div class="float-right">(단위: 명)</div>
            </div>
            <LineGraph :data="state.graph.investor.invest"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import LineGraph from "@/components/LineGraph.vue";

function Component(initialize) {
  this.name = "pageAdminStatsUtm";
  this.initialize = initialize;
}

export default {
  components: {LineGraph},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      for (let year = currYear; year >= 2015; year -= 1) {
        years.push(year);
      }

      init();
    });

    const currYear = new Date().getFullYear();
    const state = reactive({
      loaded: false,
      args: {
        year: 0
      },
      counts: {
        success: {
          sum: 0,
          reward: 0,
          invest: 0,
        },
        total: {
          reward: 0,
          invest: 0,
        }
      },
      amounts: {
        success: {
          reward: 0,
          invest: 0,
        },
        total: {
          reward: 0,
          invest: 0,
        },
        target: {
          reward: 0,
          invest: 0,
        },
        investor: {
          reward: 0,
          invest: 0,
        },
      },
      graph: {
        loaded: false,
        count: {
          sum: {},
          reward: {},
          invest: {},
        },
        amount: {
          sum: {},
          reward: {},
          invest: {},
        },
        target: {
          reward: {},
        },
        investor: {
          reward: {},
          invest: {},
        },
      }
    });

    const years = [];

    const changeYear = () => {
      const query = lib.getRenewed(router.app.$route.query);
      query.year = state.args.year;
      pushToRouter(query);
    };

    const pushToRouter = (query) => {
      if (JSON.stringify(router.app.$route.query) === JSON.stringify(query)) {
        return;
      }

      router.push({query});
    };

    const init = () => {
      state.args.year = 0;
      set();
    };

    const set = () => {
      if (state.args.year === 0) {
        setGraph();
      }

      const requests = [
        http.get("/api/admin/stats/annual/success-project-counts", {...state.args}),
        http.get("/api/admin/stats/annual/success-project-counts", {...state.args, success: true}),

        http.get("/api/admin/stats/annual/success-project-amounts", {...state.args}),
        http.get("/api/admin/stats/annual/success-project-amounts", {...state.args, success: true}),

        http.get("/api/admin/stats/annual/target-project-amounts", {...state.args}),
        http.get("/api/admin/stats/annual/project-investor-counts", {...state.args}),
      ];

      state.loaded = false;
      Promise.all(requests).then((responses) => {
        state.loaded = true;
        state.counts.total = responses[0].data.body;
        state.counts.success = responses[1].data.body;
        state.amounts.total = responses[2].data.body;
        state.amounts.success = responses[3].data.body;
        state.amounts.target = responses[4].data.body;
        state.amounts.investor = responses[5].data.body;
      });
    };

    const setGraph = () => {
      state.graph.loaded = false;

      http.get("/api/admin/stats/annual/total-annual-projects").then(({data}) => {
        state.graph.loaded = true;

        // 프로젝트 수
        state.graph.count.reward = {
          labels: data.body.count.reward.map(item => item.year),
          datasets: [
            {
              label: "성공 KIA + 성공 AON",
              data: data.body.count.reward.map(item => item.sum),
              borderColor: "#FF0000",
              backgroundColor: "#FF0000",
            },
            {
              label: "KIA + 성공 AON",
              data: data.body.count.reward.map(item => item.success),
              borderColor: "#f2c94c",
              backgroundColor: "#f2c94c",
            },
            {
              label: "전체",
              data: data.body.count.reward.map(item => item.total),
              borderColor: "#32a852",
              backgroundColor: "#32a852",
            }
          ]
        };
        state.graph.count.invest = {
          labels: data.body.count.invest.map(item => item.year),
          datasets: [
            {
              label: "성공",
              data: data.body.count.invest.map(item => item.success),
              borderColor: "#f2c94c",
              backgroundColor: "#f2c94c",
            },
            {
              label: "전체",
              data: data.body.count.invest.map(item => item.total),
              borderColor: "#1a3d7c",
              backgroundColor: "#1a3d7c",
            }
          ]
        };

        // 모집 금액
        state.graph.amount.reward = {
          labels: data.body.amount.reward.map(item => item.year),
          datasets: [
            {
              label: "성공 KIA + 성공 AON",
              data: data.body.amount.reward.map(item => item.sum),
              borderColor: "#FF0000",
              backgroundColor: "#FF0000",
            },
            {
              label: "KIA + 성공 AON",
              data: data.body.amount.reward.map(item => item.success),
              borderColor: "#f2c94c",
              backgroundColor: "#f2c94c",
            },
            {
              label: "전체",
              data: data.body.amount.reward.map(item => item.total),
              borderColor: "#32a852",
              backgroundColor: "#32a852",
            }
          ]
        };
        state.graph.amount.invest = {
          labels: data.body.amount.invest.map(item => item.year),
          datasets: [
            {
              label: "성공",
              data: data.body.amount.invest.map(item => item.success),
              borderColor: "#f2c94c",
              backgroundColor: "#f2c94c",
            },
            {
              label: "전체",
              data: data.body.amount.invest.map(item => item.total),
              borderColor: "#1a3d7c",
              backgroundColor: "#1a3d7c",
            }
          ]
        };

        // 목표 금액
        state.graph.target.reward = {
          labels: data.body.target.map(item => item.year),
          datasets: [
            {
              data: data.body.target.map(item => item.reward),
              borderColor: "#32a852",
            },
          ]
        };

        // 후원자 수
        state.graph.investor.reward = {
          labels: data.body.investor.reward.map(item => item.year),
          datasets: [
            {
              data: data.body.investor.reward.map(item => item.reward),
              borderColor: "#32a852",
            },
          ]
        };
        state.graph.investor.invest = {
          labels: data.body.investor.invest.map(item => item.year),
          datasets: [
            {
              data: data.body.investor.invest.map(item => item.invest),
              borderColor: "#1a3d7c",
            },
          ]
        };
      });
    };

    watch(() => [router.app.$route.query.year,], (next, prev) => {
      if (JSON.stringify(next) !== JSON.stringify(prev)) {
        set();
        next[0] === "0" && setGraph();
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
      }
    });

    return {component, state, years, set, changeYear, setGraph};
  }
};
</script>

<style lang="scss" scoped>
.admin-stats-annual {
  position: relative;

  .balloon {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: $px4 $px4 0 $px4;
  }

  .subject {
    font-size: $px14;
    margin-bottom: $px25;

    > .text {
      font-weight: 600;
    }
  }

  > .header {
    position: absolute;
    top: $px-55;
    right: 0;

    > .search {
      position: relative;

      select {
        height: $px43;
        width: $px120;
      }

      i {
        font-size: $px14;
      }

      > .row > .col {
        padding-left: 0;

        > select {
          font-size: $px12;
        }

        &.source {
          position: relative;

          .balloon {
            margin-right: $px129;
            margin-top: $px-15;
          }
        }
      }
    }
  }

  > .article {
    padding-top: $px15;

    > .alert {
      background: #f7f7f7;
      border-color: #eee;
      padding: $px20;
      margin-bottom: $px10;
    }

    .item {
      padding-top: $px25;
      padding-bottom: $px25;

      .subject {
        font-size: $px14;
        margin-bottom: $px5;

        > .text {
          font-weight: 600;
        }

        > .desc {
          font-size: $px12;
          margin-left: 4px;
        }
      }

      .value {
        > b {
          display: inline-block;
          font-size: $px30;
        }

        > i {
          margin: 0 15px 0 12px;
        }

        > span {
          font-size: $px16;
          margin-left: $px2;
        }
      }
    }

    &.skeleton {
      .item .value > b {
        @include skeleton;
        min-width: 200px;
      }
    }

    .graphs {
      margin-top: $px45;
    }

    .graphs > .row {
      margin-bottom: $px45;
    }
  }

  @media(max-width: 991px) {
    > .header {
      position: static;

      > .search {
        padding-right: 0;
        padding-bottom: $px30;
      }
    }
  }
}
</style>